<template>
    <div class="section-wrapper">
        <breadcumb v-if="$route.name !== 'tea_garden_service.dashboard'"/>
        <div class="form-wrapper tea-fac-db">
            <b-overlay :show="loading">
                <b-card :title="$t('teaGardenConfig.factory_registration')">
                    <b-card-text class="mb-3">
                        <b-row>
                            <b-col sm="3">
                                <router-link :to="{name: isFactoryAdminCheckFactoryId() ? 'tea_garden_service.btb.tea_factory_application' : 'tea_garden_service.approval-process.applicant_list', query:{service_id:4, status: 0,application_type: 1, limit: 100} }">
                                <div class="info-card total-app">
                                    <h3 class="value">{{ $n(totalInfo.newApplication) }}</h3>
                                    <div class="content">
                                        <div class="icon">
                                            <i class="ri-file-3-fill"></i>
                                        </div>
                                        <p>
                                        {{ $t('sitePreference.total') }} {{ $t('globalTrans.application') }}
                                        </p>
                                        <!-- <small>({{ $t('globalTrans.new') }})</small> -->
                                    </div>
                                </div>
                                </router-link>
                            </b-col>
                            <b-col sm="3">
                                <router-link :to="{name: isFactoryAdminCheckFactoryId() ? 'tea_garden_service.btb.tea_factory_application' : 'tea_garden_service.approval-process.approved_list', query:{service_id: 4, status: 6,application_type: 1, limit: 100} }">
                                <div class="info-card approved">
                                    <h3 class="value">{{ $n(totalInfo.newApprovedApplication) }}</h3>
                                    <div class="content">
                                        <div class="icon">
                                            <i class="ri-checkbox-circle-fill"></i>
                                        </div>
                                        <p>{{ $t('globalTrans.approved') }} {{ $t('globalTrans.application') }}</p>
                                        <!-- <small>({{ $t('globalTrans.new') }})</small> -->
                                    </div>
                                </div>
                                </router-link>
                            </b-col>
                            <b-col sm="3">
                                <router-link :to="{name: isFactoryAdminCheckFactoryId() ? 'tea_garden_service.btb.tea_factory_application' : 'tea_garden_service.approval-process.applicant_list', query:{service_id:4, status: 2,application_type: 1, limit: 100} }">
                                <div class="info-card pending">
                                    <h3 class="value">{{ $n(totalInfo.newPendingApplication) }}</h3>
                                    <div class="content">
                                        <div class="icon">
                                            <i class="ri-time-fill"></i>
                                        </div>
                                        <p>{{ $t('globalTrans.pending') }} {{ $t('globalTrans.application') }}</p>
                                        <!-- <small>({{ $t('globalTrans.new') }})</small> -->
                                    </div>
                                </div>
                                </router-link>
                            </b-col>
                            <b-col sm="3">
                                 <router-link :to="{name: isFactoryAdminCheckFactoryId() ? 'tea_garden_service.btb.tea_factory_application' : 'tea_garden_service.approval-process.rejected_list', query:{service_id:4, status: 7, limit: 100} }">
                                <div class="info-card reject">
                                    <h3 class="value">{{ $n(totalInfo.rejectedApplication) }}</h3>
                                    <div class="content">
                                        <div class="icon">
                                            <i class="ri-close-line"></i>
                                        </div>
                                        <p>{{ $t('globalTrans.rejected') }} {{ $t('globalTrans.application') }}</p>
                                        <!-- <small> &nbsp; </small> -->
                                    </div>
                                </div>
                                </router-link>
                            </b-col>
                            <b-col sm="3">
                                <router-link :to="{name: isFactoryAdminCheckFactoryId() ? 'tea_garden_service.btb.tea_factory_application' : 'tea_garden_service.approval-process.applicant_list', query:{service_id:4, status: 0,application_type: 2, limit: 100} }">
                                <div class="info-card total-app">
                                    <h3 class="value">{{ $n(totalInfo.renewApplication) }}</h3>
                                    <div class="content">
                                        <div class="icon">
                                            <i class="ri-file-3-fill"></i>
                                        </div>
                                        <p>{{ $t('sitePreference.total') }} {{ $t('globalTrans.application') }}</p>
                                        <!-- <small>({{ $t('globalTrans.renew') }})</small> -->
                                    </div>
                                </div>
                                </router-link>
                            </b-col>
                            <b-col sm="3">
                                <router-link :to="{name: isFactoryAdminCheckFactoryId() ? 'tea_garden_service.btb.tea_factory_application' : 'tea_garden_service.approval-process.approved_list', query:{service_id: 4, status: 6,application_type: 2, limit: 100} }">
                                <div class="info-card approved">
                                    <h3 class="value">{{ $n(totalInfo.renewApprovedApplication) }}</h3>
                                    <div class="content">
                                        <div class="icon">
                                            <i class="ri-checkbox-circle-fill"></i>
                                        </div>
                                        <p>{{ $t('globalTrans.approved') }} {{ $t('globalTrans.application') }}</p>
                                        <!-- <small>({{ $t('globalTrans.renew') }})</small> -->
                                    </div>
                                </div>
                                </router-link>
                            </b-col>
                            <b-col sm="3">
                                <router-link :to="{name: isFactoryAdminCheckFactoryId() ? 'tea_garden_service.btb.tea_factory_application' : 'tea_garden_service.approval-process.applicant_list', query:{service_id:4, status: 2,application_type: 2, limit: 100} }">
                                <div class="info-card pending">
                                    <h3 class="value">{{ $n(totalInfo.renewPendingApplication) }}</h3>
                                    <div class="content">
                                        <div class="icon">
                                            <i class="ri-time-fill"></i>
                                        </div>
                                        <p>{{ $t('globalTrans.pending') }} {{ $t('globalTrans.application') }}</p>
                                        <!-- <small>({{ $t('globalTrans.renew') }})</small> -->
                                    </div>
                                </div>
                                </router-link>
                            </b-col>
                            <b-col sm="3">
                                 <router-link :to="{name: isFactoryAdminCheckFactoryId() ? 'tea_garden_service.btb.tea_factory_application' : 'tea_garden_service.approval-process.applicant_list', query:{service_id:4, status: 0,expired_date:  new Date().toJSON().slice(0, 10), limit: 100} }">
                                <div class="info-card reject">
                                    <h3 class="value">{{ $n(totalInfo.expiredApplication) }}</h3>
                                    <div class="content">
                                        <div class="icon">
                                            <i class="ri-close-line"></i>
                                        </div>
                                        <p>{{ $t('teaGardenConfig.expired') }} {{ $t('globalTrans.application') }}</p>
                                        <!-- <small> &nbsp; </small> -->
                                    </div>
                                </div>
                                 </router-link>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
                <b-card>
                    <div id="chart" class="px-3">
                        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
                        <div class="tea-chart-number">
                            <p><i>{{ $t('globalTrans.number') }}</i></p>
                        </div>
                        <p class="tea-chart-year"><i>{{ $t('globalTrans.fiscal_year') }}</i></p>
                    </div>
                </b-card>
            </b-overlay>
        </div>
    </div>
</template>
<script>
    import RestApi, { teaGardenServiceBaseUrl } from '@/config/api_config'
    import teaGardenService from '@/mixins/tea-garden-service'
    export default {
        mixins: [teaGardenService],
        name: 'TeaFactoryRegistration',
        data () {
            return {
                teaGardenServiceBaseUrl: teaGardenServiceBaseUrl,
                totalInfo: {
                    newApplication: 0,
                    newApprovedApplication: 0,
                    newPendingApplication: 0,
                    rejectedApplication: 0,
                    renewApplication: 0,
                    renewApprovedApplication: 0,
                    renewPendingApplication: 0,
                    expiredApplication: 0
                },
                newSeriesData: [],
                renewSeriesData: [],
                categories: [],
                categoriesBn: []
            }
        },
        created () {
            this.loadData()
        },
        computed: {
            currentLocale () {
                return this.$i18n.locale
            },
            loading () {
                return this.$store.state.commonObj.loading
            },
            series () {
                return [{
                    name: `${this.$t('globalTrans.approved')} ${this.$t('globalTrans.application')} (${this.$t('globalTrans.new')})`,
                    data: this.newSeriesData
                }, {
                    name: `${this.$t('globalTrans.approved')} ${this.$t('globalTrans.application')} (${this.$t('globalTrans.renew')})`,
                    data: this.renewSeriesData
                }]
            },
            chartOptions () {
                return {
                    chart: {
                        type: 'bar',
                        height: 350
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '10%',
                            endingShape: 'rounded'
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ['transparent']
                    },
                    xaxis: {
                        categories: this.currentLocale === 'en' ? this.categories : this.categoriesBn
                    },
                    fill: {
                        opacity: 1
                    }
                }
            }
        },
        methods: {
            back () {
                this.$router.go(-1)
            },
            async loadData () {
                this.$store.dispatch('mutateCommonProperties', { loading: true })
                const result = await RestApi.getData(teaGardenServiceBaseUrl, 'dashboard/tea-factory-registration')
                if (result.success) {
                    this.totalInfo = result.data.totalInfo
                    const fiscalYearList = this.$store.state.CommonService.commonObj.fiscalYearList
                    const chartInfo = result.data.chartInfo.map(item => {
                        const fiscalYear = fiscalYearList.find(obj => obj.value === parseInt(item.fiscal_year_id))
                        if (fiscalYear) {
                                const customItem = {
                                fiscal_year_en: fiscalYear.text_en,
                                fiscal_year_bn: fiscalYear.text_bn,
                                sorting_order: fiscalYear.sorting_order
                            }
                            return Object.assign({}, item, customItem)
                        } else {
                            const customItem = {
                                fiscal_year_en: '',
                                fiscal_year_bn: '',
                                sorting_order: ''
                            }
                            return Object.assign({}, item, customItem)
                        }
                    }).sort((a, b) => a.sorting_order - b.sorting_order)
                    const categoryArr = []
                    const categoryBnArr = []
                    const newSeriesArr = []
                    const renewSeriesArr = []
                    chartInfo.forEach(item => {
                        categoryArr.push(item.fiscal_year_en)
                        categoryBnArr.push(item.fiscal_year_bn)
                        newSeriesArr.push(item.totalNewApplication)
                        renewSeriesArr.push(item.totalRenewApplication)
                    })
                    this.categories = categoryArr
                    this.categoriesBn = categoryBnArr
                    this.newSeriesData = newSeriesArr
                    this.renewSeriesData = renewSeriesArr
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false })
            }
        }
    }
</script>
<style>
/* custom chart design  */
.tea-chart-number {
    position: absolute;
    left: 0;
    top: 45%;
    transform: translate(-15px, -50%);
}
.tea-chart-number p {
    transform: rotate(-90deg);
    font-weight: 600;
}
.font-bn .tea-chart-number {
    transform: translate(0, -50%);
}
.tea-chart-year {
    text-align: center;
    margin: -12px 0 10px;
    font-weight: 600;
}
/* custom chart design  end*/
.tea-fac-db .info-card {
    margin-top: 35px;
    padding-top: 5px;
    color: #171C2A;
    border-radius: 8px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow: hidden;
}

.tea-fac-db .info-card .content {
    color: #fff;
    padding: 2px 0 2px 5px;
    margin-top: 5px;
    display: flex;
    align-items: center;
}
.tea-fac-db .info-card .content .icon {
    font-size: 14px;
    padding-right: 5px;
    border-right: 1px solid #ffffff46;
}
.tea-fac-db .info-card .content p {
    font-size: 13px;
    opacity: .9;
    padding-left: 5px;
}

.tea-fac-db .info-card .value {
    padding: 3px;
    border-radius: 10px;
}

.tea-fac-db .info-card.approved {
    background: #e2fff0;
}
.tea-fac-db .info-card.approved .value{
    color: #11b75f;
}
.tea-fac-db .info-card.approved .content {
    background: #11b75f;
}

.tea-fac-db .info-card.pending {
    background: #fff0d5;
}

.tea-fac-db .info-card.pending .value{
    color: #c78406;
}
.tea-fac-db .info-card.pending .content{
    background: #c78406;
}

.tea-fac-db .info-card.total-app {
    background: #dfe3ff;
}

.tea-fac-db .info-card.total-app .value{
    color: #1127cd;
}
.tea-fac-db .info-card.total-app .content{
    background: #1127cd;
}

.tea-fac-db .info-card.reject {
    background: #ffe6e5;
}

.tea-fac-db .info-card.reject .value{
    color: #bd180c;
}
.tea-fac-db .info-card.reject .content{
    background: #bd180c;
}
</style>
